.ShoppingCart {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
}

.product-info {
    border: 1px solid black;
    padding: 0 15px 0 15px;
    margin: 0 10px 0 10px;
    margin-top: 10px;
}

.product-img-brochure {
    max-width: 150px;
}

button {
    cursor: pointer;
}

.cart-items-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    width: 400px;
    padding-left: 0;
}

.cart-items-list li {
    border-bottom: 1px solid black;
    width: 100%;
}

.cart-items-list li span, 
.cart-items-list li footer {
    margin-top: 3%;
}

.basket {
    height: 400px;
}

.cart-item-li {
    display: flex;
    justify-content: space-evenly;
}

.cart-item-li span {
    padding-left: 10px;
}

.img-in-cart {
    max-width: 75px;
}

.item-btn {
    height: 25px;
    margin-bottom: 5px;
}

.cart-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart-footer span {
    display: flex;
    justify-content: space-evenly;
    padding-left: 10px;
}

.cart-footer span p { 
    margin: 0 5px 0 5px;
}

.cart-item-footer {
    display: flex; 
    flex-direction: column;
    align-items: center;
}

.MuiPaper-root.css-15e48vx-MuiPaper-root-MuiSnackbarContent-root {
    min-width: 150px;
}

.checkout-btn,
.submit-order-btn {
    width: 200px;
    height: 25px;
}

@media screen and (max-width: 600px) {
    .ShoppingCart {
        margin-top: 15%;
    }
    section.products-grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .cart-items-list {
        width: 85vw;
    }

    .product-details {
        height: 125px;
    }

    .product-details img {
        max-height: 75px;
    }

    .product-info {
        display: flex;
        flex-direction: column;
    }

    .product-name {
        padding: 0;
        margin: 0;
        text-align: center;
    }

    .product-info-mobile {
        display: flex;
        justify-content: space-evenly;
    }

    .product-info button {
        max-height: 50px;
        margin-top: 5px;
    }
}