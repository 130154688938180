.Navbar {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #054b93;
    color: white;
    font-family: 'Noto Sans', sans-serif;
}

.nav-links {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0 10px;
    transition: all .2s ease-in-out;
}

.nav-item {
    border: 1px solid white;
    margin-bottom: 10px;
}

.nav-item:hover {
    transform: scale(1.1, 1.1);
}

a {
    color: white;
    font-size: 18px;
    text-decoration: none;
}

a:focus {
    text-decoration: underline;
}

.logout-btn {
    background: none;
    color: white;
    font-size: 18px;
    text-decoration: none;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .Navbar {
        position: fixed;
    }
    .nav-links-mobile {
        display: flex;
        justify-content: space-evenly;
        z-index: 1;
        list-style-type: none;
        padding: 0;
        width: 100%;
    }
    .nav-item {
        border: none;
        margin-left: 5px;
        margin-right: 5px;
    }
}