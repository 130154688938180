.Products {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
}

.add-product {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-product h4 {
    padding: 0;
    margin: 0;
}

.product-info {
    border: 1px solid black;
    padding: 0 15px 0 15px;
    margin: 0 10px 0 10px;
    margin-top: 10px;
    max-height: 250px;
    width: 300px;
}

.product-list-img {
    max-width: 100px;
}

button {
    cursor: pointer;
}

.product-edit-form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    position: absolute;
    z-index: 1;
    background-color: white;
    border: 1px solid black;
    width: 300px;
}

.edit-delete-btns {
    display: flex;
    justify-content: right;
}

.product-update-display {
    display: flex;
    justify-content: space-evenly;
}
.product-update-display h4 {
    padding: 0;
    margin: 0;
}

.product-update-display input {
    height: 20px;
    padding: 0;
    width: 150px;
}

@media screen and (max-width: 600px) {
    .product-info {
        display: grid;
        grid-template-columns: 1fr;
    }

    .product-info-mobile {
        display: flex;
        justify-content: space-evenly;
    }

    .product-name {
        margin: 0;
        padding: 0;
    }

    .product-info p {
        width: 40px;
    }

    .product-edit-form {
        position: relative;
    }

    .edit-delete-btns {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .edit-delete-btns button {
        height: 20px;
        width: 90px;
        margin-left: 15px;
    }
}