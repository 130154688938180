@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

.Login {
    font-family: 'Noto Sans', sans-serif;
    display: flex; 
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    color: black;
    height: 100vh;
}

.user-login {
    display: flex; 
    flex-direction: column;
    align-items: center;
}

.login-submit-btn {
    margin-top: 10px;
    width: 100px;
    height: 30px;
    font-size: 18px;
    margin-bottom: 10px;
    cursor: pointer;
}

.create-account-btn {
    margin-left: 10px; 
    width: 150px; 
    height: 30px;
    font-size: 18px;
    cursor: pointer;
}